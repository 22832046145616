import React, { useState, useEffect } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SparesGallery(props) {
  const [width, height] = useWindowSize();
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      totalSlides={props.images.length}
      visibleSlides={width > 1023 ? 3 : 1}
      infinite
      className="spares-gallery"
    >
      <Slider>
        {props.images.map((item, index) => (
          <Slide index={index}>
            <Link to={`/products/${item.slug.current}`}>
              <Image src={item.productImage.asset.fluid.srcWebp} />
            </Link>
            {item.title && <p>{item.title}</p>}
          </Slide>
        ))}
      </Slider>
      <div className="container content-center">
        <div className="carousel-button-group centered">
          <ButtonBack>
            <FiChevronLeft />
          </ButtonBack>
          <ButtonNext>
            <FiChevronRight />
          </ButtonNext>
        </div>
      </div>
    </CarouselProvider>
  );
}
