import React from 'react';
import styled from 'styled-components';

const SelectStyled = styled.select``;

export default function ProductSizes() {
  return (
    <SelectStyled>
      <option
        value="36 / 4"
        selected="selected"
        name="Size"
        className="variant-input"
        data-index="option1"
      >
        36 / 4
      </option>
      <option
        value="37 / 4.5"
        name="Size"
        className="variant-input"
        data-index="option2"
      >
        37 / 4.5
      </option>
      <option
        value="38 / 5"
        name="Size"
        className="variant-input"
        data-index="option3"
      >
        38 / 5
      </option>
      <option
        value="39 / 6"
        name="Size"
        className="variant-input"
        data-index="option4"
      >
        39 / 6
      </option>
      <option
        value="40 / 6.5"
        name="Size"
        className="variant-input"
        data-index="option5"
      >
        40 / 6.5
      </option>
      <option
        value="40.5 / 7"
        name="Size"
        className="variant-input"
        data-index="option6"
      >
        40.5 / 7
      </option>
      <option
        value="41 / 7.5"
        name="Size"
        className="variant-input"
        data-index="option7"
      >
        41 / 7.5
      </option>
      <option
        value="42 / 8"
        name="Size"
        className="variant-input"
        data-index="option8"
      >
        42 / 8
      </option>
      <option
        value="42.5 / 8.5"
        name="Size"
        className="variant-input"
        data-index="option9"
      >
        42.5 / 8.5
      </option>
      <option
        value="43 / 9"
        name="Size"
        className="variant-input"
        data-index="option10"
      >
        43 / 9
      </option>
      <option
        value="44 / 9.5"
        name="Size"
        className="variant-input"
        data-index="option11"
      >
        44 / 9.5
      </option>
      <option
        value="44.5 / 10"
        name="Size"
        className="variant-input"
        data-index="option12"
      >
        44.5 / 10
      </option>
      <option
        value="45 / 10.5"
        name="Size"
        className="variant-input"
        data-index="option13"
      >
        45 / 10.5
      </option>
      <option
        value="46 / 11"
        name="Size"
        className="variant-input"
        data-index="option14"
      >
        46 / 11
      </option>
      <option
        value="46.5 / 11.5"
        name="Size"
        className="variant-input"
        data-index="option15"
      >
        46.5 / 11.5
      </option>
      <option
        value="47 / 12"
        name="Size"
        className="variant-input"
        data-index="option16"
      >
        47 / 12
      </option>
      <option
        value="48 / 12.5"
        name="Size"
        className="variant-input"
        data-index="option17"
      >
        48 / 12.5
      </option>
      <option
        value="49 / 13"
        name="Size"
        className="variant-input"
        data-index="option18"
      >
        49 / 13
      </option>
      <option
        value="50 / 14"
        name="Size"
        className="variant-input"
        data-index="option19"
      >
        50 / 14
      </option>
    </SelectStyled>
  );
}
