import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import styled from 'styled-components';
import Img from 'gatsby-image';

export default function ProductGallery(props) {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      totalSlides={props.images.length}
      visibleSlides={1}
      infinite
      className="product-gallery"
    >
      <Slider>
        {props.images.map((item, index) => (
          <Slide index={index}>
            <Image
              src={item.image.asset.fluid.srcWebp}
              className="carousel-image"
            />
            <div className="carousel-content">
              {item.heading && <h6>{item.heading}</h6>}
              <p>{item.caption}</p>
            </div>
          </Slide>
        ))}
      </Slider>
      <div className="container content-center">
        {props.images.length > 1 && (
          <>
            <div className="carousel-button-group centered">
              <ButtonBack>
                <FiChevronLeft />
              </ButtonBack>
              <ButtonNext>
                <FiChevronRight />
              </ButtonNext>
            </div>
          </>
        )}
      </div>
    </CarouselProvider>
  );
}
